<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex >
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Login</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              name="User"
                              label="User"
                                v-model="input.username"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              name="password"
                              label="Password"
                              v-model="input.password"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" v-on:click="login()">Login</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
export default {
   name: 'Login',
//    props: {
//       source: String,
//    },
    name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: ""
                }
            }
        },
        methods: {
            login() {
                // console.log(this.$usr);
                // console.log(this.input.username);
                // console.log(this.input.password);
                // console.log(this.$);
                if(this.input.username != "" && this.input.password != "") {
                    if(this.input.username == this.$usr && this.input.password == this.$pw) {
                        this.$emit("authenticated", true);
                        this.$router.replace({ name: "Staudensuche" });
                    } else {
                        console.log("The username and / or password is incorrect");
                    }
                } else {
                    console.log("A username and password must be present");
                }
            }
        },
        
  created() {
    document.title = "Login";
  },
};
</script>
