import Vue from 'vue'
import VueRouter from 'vue-router'
import Staudensuche from '../views/Staudensuche.vue'
import LoginComponent from "../views/login.vue"

Vue.use(VueRouter)

const routes = [

    {
        path: '/x1F90ol_3pu7-39_sdRRw3p4',
        redirect: {
            name: "Staudensuche"
        }
    },
    {
        path: '/',
        redirect: {
            name: "login"
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginComponent
    },
    {
        path: '/Staudensuche',
        name: 'Staudensuche',
        component: Staudensuche
    },

    // {
    //     path: '/navigateBeet',
    //     redirect: {
    //         name: "Staudensuche"
    //     }
    // },
    {
        path: '/navigateBeet',
        name: 'navigateBeet',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "navigateBeet" */ '../views/navigateBeet.vue')
    },
    {
        path: '/beetView',
        name: 'beetView',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "beetView" */ '../views/beetView.vue')
    },
    {
        path: '/newPosition',
        name: 'newPosition',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "beetView" */ '../views/newPosition.vue')
    },
    {
        path: '/navigateAuftrag',
        name: 'navigateAuftrag',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "beetView" */ '../views/navigateAuftrag.vue')
    },
    {
        path: '/auftragsView',
        name: 'auftragsView',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "beetView" */ '../views/auftragsView.vue')
    }

]

const router = new VueRouter({
    routes
})


export default router