<!-- <template>
    <div id="app">
        <div id="nav">
            <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link>
        </div>
        <router-view @authenticated="setAuthenticated" />
    </div>
</template> -->
<template>
  <v-app id="inspire">
   <template v-if="!$route.path.includes('login')">
    <v-navigation-drawer 
      v-model="drawer"
      app
      >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Staudenapp
          </v-list-item-title>
          <v-list-item-subtitle>
            Larifari
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to=item.to
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
   </template>
      <!-- <v-main>
         <keep-alive :include="['Login']">
            <router-view></router-view>
         </keep-alive>
      </v-main> -->
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Staudenapp</v-toolbar-title>

    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

  export default {
    data: () => ({ 
        authenticated: false,
        drawer: null ,
        items: [
          { title: 'Staudensuche', icon: 'mdi-magnify' , to:'/Staudensuche'},
          { title: 'Beet anzeigen', icon: 'mdi-map-marker', to:'/navigateBeet'},
          { title: 'Pflanze platzieren', icon: 'mdi-plus', to:'/newPosition'},
          { title: 'Aufträge', icon: 'mdi-format-list-bulleted', to:'/navigateAuftrag'},
          
          
        ]
         }),

        mounted() {
            // if(!this.authenticated) {
                // this.$router.replace({ name: "login" });
            // }
        },
        methods: {
            setAuthenticated(status) {
                this.authenticated = status;
            },
            logout() {
                this.authenticated = false;
            }
        },
         created () {
            document.title = "Staudenapp";
        }
        // beforeCreate () {
        //   }
  }
</script>
