<template>
  <div class="home">
    <v-text-field placeholder="Search" v-model="searchString"></v-text-field>

    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in plants" :key="i">
        <v-expansion-panel-header>
          <v-row align="center" justify="center">
            <v-col cols="2">
              <v-icon
                slot="prependIcon"
                :color="item.col"
                @click.native="showpflanzenRename($event, item)"
                >mdi-sprout
              </v-icon>
            </v-col>
            <v-col cols="10">
              {{ item.name }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels>
            <v-expansion-panel v-for="(pos, ii) in item.positions" :key="ii">
              <v-expansion-panel-header>
                {{ pos.grundNam + " Beet " + pos.beet }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  {{
                    "Beet " +
                    pos.beet +
                    " " +
                    pos.section +
                    ", Reihe " +
                    pos.position
                  }}
                </v-row>
                <v-row>
                  {{ "Menge: " + pos.menge }}
                </v-row>
                <v-row>
                  {{ "Datum: " + pos.datum }}
                </v-row>
                <v-row>
                  {{ "Bem.: " + pos.bemerkung }}
                </v-row>
                <v-row>
                  <v-col sm="6" md="4" cols="3">
                    <v-icon medium @click="deleteId(pos)">
                      mdi-trash-can-outline
                    </v-icon>
                  </v-col>
                  <v-col sm="6" md="4" cols="3">
                    <v-icon medium @click="editPosition(pos, item)">
                      mdi-pencil
                    </v-icon>
                  </v-col>
                  <v-col sm="6" md="4" cols="3">
                    <v-icon medium @click="gotoBeet(pos, item)">
                      mdi-map-marker
                    </v-icon>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="stauDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>das funktioniert noch nicht</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm="6" md="4" cols="3">
                <p>Nix:</p>
              </v-col>
              <v-col sm="6" md="4" cols="9">
                <v-text-field
                  label="nix"
                  v-model="position.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="stauDialog = false">
            Close
          </v-btn>
          <v-btn color="primary" text @click="savePlant">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="posDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>{{ staude.name }}:</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="4">
                <p>Menge:</p>
              </v-col>
              <v-col sm="6" md="4" cols="8">
                <v-text-field
                  type="number"
                  :label="position.menge.toString()"
                  v-model="newPosition.menge"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newPosition.datum"
                      :label="position.datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newPosition.datum"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col sm="6" md="4" cols="3">
                              <p>Dat.:</p>
                            </v-col>
                            <v-col sm="6" md="4" cols="9">
                              <v-text-field :label=position.datum
                              v-model=newPosition.datum></v-text-field>
                            </v-col> -->
            </v-row>
            <v-row align="center" justify="center">
              <v-col sm="6" md="4" cols="3">
                <p>Bem.:</p>
              </v-col>
              <v-col sm="6" md="4" cols="9">
                <v-text-field
                  :label="position.bemerkung"
                  v-model="newPosition.bemerkung"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="posDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="savePosition"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StaudenSuche",
  data: () => ({
    menuDate: false,
    posDialog: false,
    stauDialog: false,
    newPosition: { name: "", bemerkung: "", menge: 0, datum: "", date: null },
    position: { name: "", bemerkung: "", menge: 0, datum: "", date: null },
    staude: [],
    newStaude: [],
    searchString: "",
    plants: [],
  }),

  methods: {
    gotoBeet(pos, item) {
      // console.log(item)
      // console.log(pos)
      this.$router.push({
        name: "beetView",
        params: {
          grundstuck: pos["grundID"],
          grundstuckName: pos["grundNam"],
          beet: pos["beet"],
          posID: pos["posID"],
        },
      });
    },
    editPosition(position, pfl) {
      // console.log(pfl.name);
      this.staude = pfl;
      this.position = { ...position };
      this.newPosition = { ...position };
      let myDate = new Date();
      const offset = myDate.getTimezoneOffset();
      myDate = new Date(myDate.getTime() - offset * 60 * 1000);
      this.newPosition.datum = myDate.toISOString().split("T")[0];
      this.posDialog = true;
    },
    savePlant() {
      this.stauDialog = false;
    },
    savePosition() {
      for (var i = 0; i < this.plants.length; i++) {
        var pl = this.plants[i];
        for (var i2 = 0; i2 < pl.positions.length; i2++) {
          var pos = pl.positions[i2];
          if (pos.posID == this.newPosition.posID) {
            this.plants[i].positions[i2] = { ...this.newPosition };
          }
        }
      }
      const params = new URLSearchParams();
      // console.log(this.newPosition);

      for (let key in this.newPosition) {
        params.append(key, this.newPosition[key]);
      }
      axios
        .post(this.$hostname + "/updatePosition.php", params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          console.log(response.data);
        });
      this.posDialog = false;
    },
    showpflanzenRename(evt, item) {
      evt.cancelBubble = true;
      this.stauDialog = true;
    },
    deleteId(id) {
      if (confirm("Standort löschen?")) {
        // axios.post('http://localhost:80/stauden_backend_php/deletePosition.php', { posID: id})
        //   .then(response => console.log(response));
        console.log(id);
        axios
          .get(this.$hostname + "/deletePosition.php?posID=" + id["posID"])
          .then((response) => console.log(response.data));
        axios
          .get(
            this.$hostname +
              "/plantSearch.php?searchString=" +
              encodeURIComponent(this.searchString)
          )
          .then((response) => (this.plants = response.data));
      }
    },
    loadSearch() {
      // console.log(axios)

      // axios.get('http://webcode.me').then(resp => {
      //     console.log(resp.data);
      // });
      axios
        .get(
          this.$hostname +
            "/plantSearch.php?searchString=" +
            encodeURIComponent(this.searchString)
        )
        .then((response) => (this.plants = response.data));
      // axios.get('http://localhost:80/stauden_backend_php/plantSearch.php?searchString='+encodeURIComponent(this.searchString)).then(response => (console.log(response.data)));
      // this.plants=[]
    },
    getPreisString(preise) {
      console.log(preise);
      // return("asd");
      return preise.join("€, ") + "€";
    },
  },
  watch: {
    searchString: function () {
      if (this.searchString.length > 2) {
        this.loadSearch();
      } else {
        this.plants = [];
      }
    },
  },
};
</script>
