import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VuetifyDialog from "vuetify-dialog";

Vue.config.productionTip = false

Vue.use(VuetifyDialog);

// Vue.prototype.$hostname = 'http://localhost:80/stauden_backend_php/staudenbackend'; // local server test
Vue.prototype.$usr = "tstasdfasfds";
Vue.prototype.$pw = "tstasdfasdf";
Vue.prototype.$hostname = 'https://eidm.4lima.de/staudenbackend/staudenbackend';


var myMixin = {
    // created: function() {
    //     // this.hello()
    // },
    // methods: {
    //     hello: function() {
    //         console.log('hello from mixin!')
    //     }
    // }
}

var app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mixins: [myMixin],
});

// app.use(function(req, res, next) {
//     res.header("Access-Control-Allow-Origin", "*");
//     res.header("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
//     res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization");
//     next();
// });

app.$mount('#app');